<template>
  <v-container fill-height>
    <v-row justify="center" align="center" no-gutters>
      <v-col md="4">
        <div class="text-h6 text-center mb-3">Авторизация</div>
        <v-form>
          <v-text-field v-model="username" label="Логин" @keyup.enter="submit" outlined dense />
          <v-text-field v-model="password" type="password" label="Пароль" @keyup.enter="submit" outlined dense />
          <div class="text-center">
            <v-btn color="primary" elevation="0" :disabled="isLoading" @click="submit">Войти</v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: '',
      isLoading: false,
      hasError: false,
    };
  },
  methods: {
    ...mapActions('auth', ['LOG_IN']),
    async submit() {
      this.isLoading = true;

      try {
        await this.LOG_IN({ username: this.username, password: this.password });
        this.$router.push('/');
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
